import { createLazyFileRoute } from "@tanstack/react-router";
import { NetworkCreate, type NetworkFormType } from "../../../modules/network/NetworkCreate.tsx";
import { useSimpleRouteParam } from "../../../utils/router/useSimpleRouteParam.tsx";

export const Route = createLazyFileRoute("/_main/network/create/$type")({
    component: NetworkCreateComponent,
});

function NetworkCreateComponent() {
    const [type, setType] = useSimpleRouteParam(Route.useParams(), "type");

    return <NetworkCreate typeId={validateType(type)} onChangeType={setType} />;
}

function validateType(type: string) {
    const types: string[] = ["ip_address", "vpc", "load_balancer"] satisfies NetworkFormType[];
    return types.includes(type) ? (type as NetworkFormType) : undefined;
}

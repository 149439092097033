import TF from "../../../components/forms/TextField.module.css";
import TC from "../../../components/table/Table.module.css";

import { useEffect, useState } from "react";
import { FieldError, Input, Label, NumberField } from "react-aria-components";
import { splice } from "remeda";
import { DeleteButton } from "../../../components/button/DeleteButton.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";

export interface RulesProps {
    source_port: number;
    target_port: number;
}

export interface ForwardingRulesListProps {
    onInputChange?: (value: RulesProps[]) => void;
    defaultRules?: RulesProps[];
}

export function ForwardingRulesList({ defaultRules, onInputChange }: ForwardingRulesListProps) {
    const [rules, setRules] = useState<RulesProps[]>(defaultRules ?? []);

    useEffect(() => {
        onInputChange?.(rules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rules]);

    function onAdd() {
        const items = [...rules, { source_port: NaN, target_port: NaN }];
        setRules(items);
    }

    function onDelete(index: number) {
        const items = splice(rules, index, 1, []);
        setRules(items);
    }

    function setRulePortValue(index: number, key: "source_port" | "target_port", value: number) {
        const items = rules.map((item, i) => (i === index ? { ...item, [key]: value } : item));
        setRules(items);
    }

    function validatePort(value: number) {
        return !(value > 0 && value < 65536) ? "Port must be between 1 and 65535." : undefined;
    }

    function validateSourcePort(value: number) {
        const found = rules.filter((item) => item.source_port === value);
        return found.length > 1 ? "Source port must be unique" : validatePort(value);
    }

    return (
        <>
            <WTable className={TC.rules}>
                <thead>
                    <tr>
                        <th>Protocol</th>
                        <th className="text-right">Port</th>
                        <th />
                        <th>Target port</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {rules.map((item, index) => (
                        <tr key={index}>
                            <td>TCP</td>
                            <td className="text-right">
                                <NumberField
                                    className={TF.NumberField}
                                    name="source_port"
                                    onChange={(e) => setRulePortValue(index, "source_port", e)}
                                    defaultValue={item.source_port}
                                    validate={validateSourcePort}
                                    isRequired
                                    formatOptions={{
                                        useGrouping: false,
                                    }}
                                >
                                    <Label className="hidden">Source port</Label>
                                    <div>
                                        <Input style={{ width: "80px" }} className={TF.Input} />
                                    </div>
                                    <FieldError />
                                </NumberField>
                            </td>
                            <td className="text-center">
                                <MaskIcon className="jp-arrow-thin-right-icon size-1.25rem color-muted" />
                            </td>
                            <td>
                                <NumberField
                                    className={TF.NumberField}
                                    onChange={(e) => setRulePortValue(index, "target_port", e)}
                                    name="target_port"
                                    defaultValue={item.target_port}
                                    validate={validatePort}
                                    isRequired
                                    formatOptions={{
                                        useGrouping: false,
                                    }}
                                >
                                    <Label className="hidden">Target port</Label>
                                    <div>
                                        <Input style={{ width: "80px" }} className={TF.Input} />
                                    </div>
                                    <FieldError />
                                </NumberField>
                            </td>
                            <td className="text-right">
                                <DeleteButton inTable action={() => onDelete(index)} />
                            </td>
                        </tr>
                    ))}
                </WTableBody>
            </WTable>

            <WButton
                color="primary"
                variant="dashed"
                icon="jp-icon-add"
                action={() => onAdd()}
                className="w-full"
                isDisabled={rules.length >= 8}
            >
                Add New Rule
            </WButton>
        </>
    );
}

import type { LbCreateTarget } from "@warrenio/api-spec/spec.oats.gen";
import { useEffect, useState } from "react";
import { splice } from "remeda";
import { Badge } from "../../../components/Badge.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { DeleteButton } from "../../../components/button/DeleteButton.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import type { VirtualMachineLoc } from "../../compute/vmQuery.ts";
import { vmQueryAtom } from "../../compute/vmQuery.ts";
import { TargetServersSelect, type TargetServersSelectParams } from "./TargetServersSelect.tsx";
import { convertTargetType } from "./loadBalancer.logic.ts";

export interface TargetServersListProps extends Omit<TargetServersSelectParams, "usedResourceUuids"> {
    location: string;
    onInputChange?: (value: LbCreateTarget[]) => void;
    defaultTargets?: LbCreateTarget[];
}

function ItemBadge({ item }: { item?: VirtualMachineLoc }) {
    return <Badge color={item?.status === "running" ? "success" : "error"}>{item?.status}</Badge>;
}

export function TargetServersList({ defaultTargets, onInputChange, ...props }: TargetServersListProps) {
    const { networkUuid, location } = props;
    const vms = useSuspenseQueryAtom(vmQueryAtom(location));

    const [targets, setTargets] = useState<LbCreateTarget[]>(defaultTargets ?? []);

    useEffect(() => {
        setTargets([]);
    }, [networkUuid]);

    useEffect(() => {
        onInputChange?.(targets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targets]);

    function onAdd(vm: VirtualMachineLoc) {
        setTargets((targets) => [...targets, { target_type: "vm" as const, target_uuid: vm.uuid }]);
    }

    function onDelete(index: number) {
        setTargets((targets) => splice(targets, index, 1, []));
    }

    return (
        <>
            <div className="mb-3 lightBlock">
                <TargetServersSelect
                    // Always keep nothing selected
                    valueKey={null}
                    onChange={onAdd}
                    usedResourceUuids={targets.map((a) => a.target_uuid)}
                    {...props}
                />
            </div>

            <WTable>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Private IP</th>
                        <th>Status</th>
                        <th/>
                    </tr>
                </thead>
                <WTableBody>
                    {targets.map((item, index) => {
                        const resource = vms.get(item.target_uuid);

                        return (
                            <tr key={index}>
                                <td>{resource?.name}</td>
                                <td>
                                    <ResourceWithIcon type={convertTargetType(item.target_type)} />
                                </td>
                                <td>{resource?.private_ipv4}</td>
                                <td>
                                    <ItemBadge item={resource} />
                                </td>
                                <td className="text-right">
                                    <DeleteButton inTable action={() => onDelete(index)} />
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
            </WTable>
        </>
    );
}
